import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Banner } from "../components/banner"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { mq } from "../layouts/theme"
import { useTheme } from "emotion-theming"
import { ButtonLink } from "../components/button-link"
import formatDistanceStrict from "date-fns/formatDistanceStrict"
console.log(process.env.NODE_ENV)
const jobs = (() => {
  switch (process.env.NODE_ENV) {
    case "development":
      return Array(5).fill({
        title: "Research Analyst",
        posted: new Date("2020-08-10"),
        location: "Pasig",
        typ: "Full-time",
        specs: [
          "Bachelor's Degree",
          "2 Years Experience",
          "Proficient in English",
        ],
      })
    case "production":
    default:
      return []
  }
})()

const P = styled.p`
  letter-spacing: 1px;
  margin-bottom: 16px;
  font-size: 12px;
  font-family: ProximaNova-Regular;
  text-align: center;
`

const Career = () => {
  const {
    colors: {
      bg: { lightGrey },
      txt: { link },
    },
  } = useTheme()
  const { banner, ogImg } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "careers-banner.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ogImg: file(relativePath: { eq: "careers-banner.png" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Careers"
        description="We live to develop and build dream homes for all types of people.
            Join our team!"
        img={ogImg.childImageSharp.fixed.src}
      />
      <Banner img={banner} bgPosition={"bottom"} />
      <section
        css={css`
          padding: 50px 20px;
          background: ${lightGrey};
          ${mq.mobile} {
            padding: 50px 40px;
          }
          ${mq.tablet} {
            padding: 50px;
          }
          flex: 1;
        `}
      >
        <div
          css={css`
            max-width: 1300px;
            margin: 0 auto;
          `}
        >
          <h2
            css={css`
              font-family: ProximaNova-Bold;
              letter-spacing: 3px;
              text-align: center;
            `}
          >
            CAREERS
          </h2>
          <P
            css={css`
              margin-bottom: 35px;
            `}
          >
            We live to develop and build dream homes for all types of people.
            Join our team!
          </P>
          {(() => {
            if (jobs.length > 1) {
              return (
                <div
                  css={css`
                    display: grid;
                    grid-gap: 20px;
                    grid-template-columns: 1fr;

                    ${mq.mobile} {
                      grid-template-columns: 1fr 1fr;
                    }
                    ${mq.tablet} {
                      grid-template-columns: 1fr 1fr 1fr;
                    }
                    ${mq.desktop} {
                      grid-template-columns: 1fr 1fr 1fr;
                    }
                    ${mq.lgDesktop} {
                      grid-template-columns: 1fr 1fr 1fr 1fr;
                    }
                  `}
                >
                  {jobs.map(({ title, posted, location, typ, specs }, i) => {
                    return (
                      <div
                        key={i}
                        css={css`
                          display: flex;
                          flex-direction: column;
                          padding: 20px 25px;
                          background: white;
                        `}
                      >
                        <h6
                          css={css`
                            text-transform: uppercase;
                            font-size: 20px;
                            color: ${link};
                            margin-bottom: 10px;
                            font-family: ProximaNova-Bold;
                          `}
                        >
                          {title}
                        </h6>
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                          `}
                        >
                          <img
                            css={css`
                              width: 12px;
                              margin-right: 5px;
                            `}
                            alt="clock"
                            src={require("../images/clock-icon.png")}
                          />
                          <span
                            css={css`
                              font-family: ProximaNova-Regular;
                              font-size: 12px;
                            `}
                          >
                            posted{` `}
                            {formatDistanceStrict(posted, new Date(), {
                              addSuffix: true,
                              unit: "day",
                            })}
                          </span>
                        </div>
                        <p
                          css={css`
                            font-family: ProximaNova-Semibold;
                            font-size: 12px;
                            margin: 0;
                            margin-top: 5px;
                            margin-bottom: 5px;
                          `}
                        >
                          {location} | {typ}
                        </p>
                        <div>
                          {specs.map(spec => {
                            return (
                              <p
                                css={css`
                                  font-family: ProximaNova-Regular;
                                  font-size: 12px;
                                  margin: 0;
                                `}
                              >
                                - {spec}
                              </p>
                            )
                          })}
                        </div>
                        <div
                          css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 20px;
                          `}
                        >
                          <ButtonLink to={"/contact"} label="SEND MESSAGE" />
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            } else {
              return (
                <h3
                  css={css`
                    letter-spacing: 1px;
                    font-family: ProximaNova-Bold;
                    font-size: 16px;
                    color: ${link};
                    text-align: center;
                  `}
                >
                  NO AVAILABLE POSITIONS
                </h3>
              )
            }
          })()}
        </div>
      </section>
    </>
  )
}

export default Career
